import {email, required, minLength, equal, minValue, pass} from './validators'
let validator = {
    validate: (rules, value)=>{
        let errors = [];
        rules.forEach((ritem)=>{
            if (ritem.rule !== undefined) {
                if (validator[ritem.rule] !== undefined) {
                    let check = validator[ritem.rule](value, ritem.option);
                    if (check !== null) errors.push(check);
                }
            }else{
                if (typeof(ritem) == 'string' && validator[ritem] !== undefined) {
                    let check = validator[ritem](value);
                    if (check !== null) errors.push(check);
                }
            }
        });

        return errors;
    },
    email: (item)=>{
        return email(item) ? null : 'This field requires a properly formatted email address';
    },
    required: (item)=>{
        return required(item) ? null : 'This field required';
    },
    pass: (item)=>{
        return pass(item) ? null : 'Should contain letters, numbers, symbols: !@#$%^&*-+.,_';
    },
    tos: (item, option)=>{
        return equal(item, option) ? null : 'You must agree to our terms to continue with a purchase';
    },
    min: (item, option)=>{

        return minLength(item, option) ? null : 'This field should have at least ' + option + ' symbols.'
    },
    minValue: (item, option)=>{

        return minValue(item, option) ? null : 'This field should have value ' + option + ' or more'
    },
    equal: (item, option)=>{

        return equal(item, option) ? null : 'This field should be equal to: ' + option;
    },


};
export default validator;
