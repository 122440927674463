import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Env from "../../env.js"
// import qs from "qs";

Vue.use(Vuex);
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
let headers = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
// let headers = {headers: {'Content-Type': 'multipart/form-data'}};
function mapProducts(products) {
    let mappedProducts = [];
    products.map((product)=>{
        if (product.meta.ipVersion == 4) {
            mappedProducts.push(product);
        }else if (product.meta.ipVersion == 6) {
            product.meta.category = 'dedicated';
            product.meta.country = 'us';
                mappedProducts.push(product);
        }
        if (product.meta.category === 'static-res') {
            product.trial = 0;
        }
    });

    return mappedProducts;
}
export default new Vuex.Store({
    state: {
        user: null,
        user_register_errors: [],
        user_register_validation_errors: [],
        processing: false,
        new_order: null,
        availability: [],
        all_products: []
    },
    mutations: {

        SET_USER_DATA(state, userData) {
            localStorage.setItem('user', JSON.stringify(userData));
            state.user = userData.data;
        },
        SET_PRODUCTS(state, products) {
            state.all_products = products;
        },
        SET_AVAILABILITY(state, availability) {
            state.availability = availability;
        },
        SET_PROCESSING(state, led) {
            state.processing = led;
        },
        SET_NEW_ORDER(state, data) {
            state.new_order = data;
        },
        SET_USER_REGISTER_ERRORS(state, errors) {
            state.user_register_errors = errors;
        },
        SET_USER_REGISTER_VALIDATION_ERRORS(state, errors) {
            state.user_register_validation_errors = errors;
        }
    },
    actions: {

        register({commit}, details) {
            commit('SET_USER_REGISTER_ERRORS', []);
            return axios.post(Env.ab_url + '/api/_/call_billing?action=user/add', details)
                .then(({data}) => {
                    if (data.status == 'success') {
                        Vue.$toast.open({
                            message: 'New user account created',
                            position: 'top-right',
                        });
                        commit('SET_USER_DATA', data);
                    } else {
                        if (data.error.code !== undefined && data.error.code === 'VALIDATION') {
                            commit('SET_USER_REGISTER_VALIDATION_ERRORS', data.error.errors);
                        } else if (data.status === 'error' && data.error.code === 'NOT_VALID_TFA') {
                            //we need get tfa to proceed with registration
                        } else {
                            Vue.$toast.open({
                                message: 'Something went wrong. Please try later',
                                position: 'top-right',
                                type: 'error'
                            });
                        }
                    }

                    return data;
                })
                .catch((err) => {
                    Vue.$toast.open({
                        message: 'Connection errors. Please try later',
                        position: 'top-right',
                        type: 'warning'
                    });
                })
        },
        getProducts({commit}) {
            // commit('SET_PROCESSING', true);

            return axios.get(Env.ab_url + '/api/be/pricing_tiers', headers)
                .then(({data}) => {
                    commit('SET_PRODUCTS', mapProducts(data.pricing));

                })
        },
        getAvailability({commit}) {

            return axios.get(Env.ab_url + '/api/pb/api/reseller/v2.1/misc/proxy/locations/2539', headers)
                .then(({data}) => {
                    commit('SET_AVAILABILITY', data.list);

                })
        },
        createOrder({commit}, details) {
            // commit('SET_PROCESSING', true);
            // axios.defaults.withCredentials = true;
            return axios.post(Env.ab_url + '/api/be/checkout', details)
                .then(({data}) => {
                    console.log(data);
                    if (data.status == 'success') {
                        Vue.$toast.open({
                            message: 'New order account created',
                            position: 'top-right',
                        });
                        commit('SET_NEW_ORDER', data);
                    } else {

                        console.log(data);
                        Vue.$toast.open({
                            message: 'Something went wrong. Order was not created',
                            position: 'top-right',
                            type: 'error'
                        });
                    }
                })
                .catch((err) => {
                    Vue.$toast.open({
                        message: 'Connection errors. Please try later',
                        position: 'top-right',
                        type: 'error'
                    });
                });

        },
        setValidationErrors({commit}, errors) {
            commit('SET_USER_REGISTER_VALIDATION_ERRORS', errors)
        }

    },
    modules: {}
})
