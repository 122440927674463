<template>
  <div class="inform-block">
    <img src="@/assets/free_trial_icon.png" alt="Free Trial">
    <div class="text-block">
      <p>
        <strong>Risk-Free Trial:</strong> By signing up, you are eligible to try any package* of 5 proxies for two
        days, risk-free.<br>
        Contact <a href="mailto:support@rayobyte.com">support@rayobyte.com</a> within 48 hours of your purchase to request a full refund.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrialInfoBlock"
}
</script>

<style scoped>
.inform-block {
  background: #F6F7FB;
  border: 1px solid #FFFFFF;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
}
.inform-block img {
  width: 85px;
}
.inform-block p {
  line-height: 20px;
  font-weight: 400;
  margin: 5px;
}
.text-block p {
  width: 100%;
}
.inform-block a {
  color: #07B6BF;
}
.note-color {
  color: #EE3889;
}
</style>
