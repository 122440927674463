import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home.vue'
import Purchase from '../views/pages/Purchase.vue'
import NotFoundComponent from '../views/pages/NotFoundComponent'
import MainContainer from '../views/layouts/MainContainer.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/purchase',
    name: 'Main',
    component: MainContainer,
    meta: {
      title: 'Rayobyte'
    },
    children: [

      {
        path: 'purchase',
        name: 'Purchase',
        component: Purchase,
        meta: {
          title: 'Rayobyte Shopping Cart'
        },

      },
      {
        path: '/Home',
        name: 'Home',
        component: Home,
        meta: {
          title: 'Rayobyte home page'
        },
      }
    ]

  }
  ,
  { path: '*', component: NotFoundComponent }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next()
});

export default router
