<template>
  <div class="package-caption-wrapper">
    <div
        v-for="item in package_plans"
        class="package-caption-item"
    >
      <strong>{{ item.title }}:</strong>
      <span class="caption-val">{{ item.caption }}</span>
      <span class="discount-val">{{ item.discount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackageCaptionList",
  data () {
    return {
      package_plans: [
        {title: 'Starter', caption: '5 to 99', discount: ''},
        {title: 'Personal', caption: '100 to 999', discount: '-5%'},
        {title: 'Corporate', caption: '1k to 4,999', discount: '-10%'},
        {title: 'Enterprise', caption: '5k+', discount: '-20%'},
      ]
    }
  }
}
</script>

<style scoped>
.package-caption-wrapper {
  margin: 0 14px;
  display: flex;
  width: 100%;
}
.package-caption-item {
  display: inline-flex;
  font-size: 12px;
  line-height: 18px;
  margin-right: 18px;
  color: #505050;
}
.package-caption-item:last-child {
  margin-right: 0;
}
.package-caption-item strong {
  font-weight: 700;
}
.package-caption-item .caption-val {
  margin: 0 4px;
}
.package-caption-item .discount-val {
  color: #EE3889;
}

</style>
