<template>
    <v-row class="mt-5 mb-2 pl-4 pl-md-0">
        <div
                class="text-h7"
        >
            <v-avatar class="mx-3"
                      color="grey lighten-1"
                      size="30"
            >
                <span class="white--text headline">{{ num }}</span>
            </v-avatar>
                <slot></slot>
        </div>
    </v-row>
</template>

<script>
    export default {
        props: {
            num: {
                num: Number,
            },
        },
        name: 'ListHeadingPurchase'

    }
</script>

<style scoped>

</style>
