<template>
  <v-row class="navigation-wrapper">
    <div class="top-header-block">
      <AdditionalLinks></AdditionalLinks>
    </div>
    <v-row class="top-menu-wrapper">
      <v-col cols="2">
        <a
            href="https://rayobyte.com/proxy/dashboard/"
            target="_blank"
            title="Rayobyte"
            class="d-flex align-content-center flex-wrap logo-wrapper"
        >
          <img src="@/assets/logo.png"  alt="Rayobyte"/>
        </a>
      </v-col>
      <v-col
          class="main-menu-wrapper"
          justify="center"
          no-gutters
          cols="8"
          sm="10"
          md="10"
      >
        <HeaderMenu></HeaderMenu>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import HeaderMenu from "@/components/ui/HeaderMenu";
import AdditionalLinks from "@/components/ui/AdditionalLinks";
export default {
  name: "Header",
  components: {AdditionalLinks, HeaderMenu},
  data: () => ({
    currentUrl: "",
  }),
  created() {
    this.currentUrl = window.location.href;
  },
}
</script>

<style scoped>
.navigation-wrapper {
  margin: 0;
}
.top-header-block {
  width: 100%;
  height: 30px;
  background: #3D3D3D;
}
.top-menu-wrapper {
  background: rgba(40, 40, 40, 0.6);
  height: 85px;
  padding: 0 50px;
  margin: 0;
}
.logo-wrapper {
  margin-top: 16px;
}
.logo-wrapper img {
  width: 150px;
}
@media screen and (max-width: 960px) {
  .top-menu-wrapper {
    padding: 0 30px;
  }
}
@media screen and (max-width: 680px) {
  .top-menu-wrapper {
    padding: 0 10px;
  }
}

</style>
