<template>
    <div>
        <SimpleInput></SimpleInput>
    </div>
</template>

<script>
    import SimpleInput from '../../components/ui/SimpleInput'
    export default {
        components: {
            SimpleInput
        },
    }
</script>

<style scoped>

</style>
