<template>
    <div>
        <div class="b-control">
            <input type="text" class="input" placeholder="Simple Input">
        </div>
    </div>

</template>

<script>
    export default {
        name: 'SimpleInput'

    }
</script>

<style scoped>

    .b-input{
        padding: 2px 8px;
        border: 1px solid #D5E0F4;
        border-radius: 4px;
        width: 100%;
        font-size: 1rem;
        height: 2.25em;
        box-shadow: inset 0 0 0.1em rgba(0,0,0,0.5);
    }

</style>
