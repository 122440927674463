<template>
  <div class="text-center d-flex align-center justify-space-around">

    <div class="navbar">
      <div v-for="mItem in mainMenu" class="navbar-item">
        <a :href="mItem.url" target="_blank" v-if="!mItem.subMenu">
          <span>{{ mItem.name }}</span>
        </a>
        <div class="sub-nav" v-else>
          <button class="sub-nav-btn">
            <span>{{ mItem.name }}</span>
            <v-icon>expand_more</v-icon>
          </button>
          <div class="sub-nav-content">
            <div v-for="item in mItem.children" class="sub-menu-item">
              <a :href="item.url" target="_blank" class="item-content">
                <div class="menu-icon-wrapper">
                  <img :src="item.icon" :alt="item.name">
                </div>
                <div class="item-caption-wrapper">
                  <div class="sub-menu-title">{{ item.name }}</div>
                  <div class="sub-menu-caption">{{ item.caption }}</div>
                </div>
              </a>
            </div>
            <div class="see-all-wrapper" v-if="mItem.titleCaption">
              <div class="see-all-item">
                <div class="title">{{ mItem.titleCaption }}</div>
                <div class="caption">{{ mItem.caption }}</div>
              </div>
              <div class="see-all-item">
                <a :href="mItem.url" target="_blank" class="learn-more-btn">Learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-app-bar class="mobile-nav-bar">
      <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-md-none"
      ></v-app-bar-nav-icon>

    </v-app-bar>
    <!-- Add a navigation bar -->
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        class="mobile-nav-content"
    >
      <v-treeview
          rounded
          hoverable
          activatable
          :items="mainMenu"
      >
        <template v-slot:label="mItem">
          <a :href="mItem.item.url" target="_blank">
            <span>{{ mItem.item.name }}</span>
          </a>
        </template>
      </v-treeview>
    </v-navigation-drawer>
    <!-- Navigation bar ends -->
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
  data: () => ({
    drawer: false,
    items: [
      {
        id: 1,
        name: 'Applications :',
        children: [
          { id: 2, name: 'Calendar : app' },
          { id: 3, name: 'Chrome : app' },
          { id: 4, name: 'Webstorm : app' },
        ],
      },
    ],
    mainMenu: [
      {
        id: 1,
        name: 'Proxies',
        titleCaption: 'All Products',
        caption: 'Click here to compare our products on price, speed, and authority for all use cases.',
        url: 'https://rayobyte.com/products',
        subMenu: true,
        children: [
          {
            name: 'Residential Proxies',
            icon: require('@/assets/menu_icons/resi_proxy.svg'),
            url: 'https://rayobyte.com/products/residential-proxies/',
            caption: 'Ethical residential proxies for all of your data needs'
          },
          {
            name: 'Rotating ISP Proxies',
            icon: require('@/assets/menu_icons/rotating_isp_proxy.svg'),
            url: 'https://rayobyte.com/rotating-isp-proxies/',
            caption: 'Harness the the power of both data center and residential IPs'
          },
          {
            name: 'Data Center Proxies',
            icon: require('@/assets/menu_icons/data_center_proxy.svg'),
            url: 'https://rayobyte.com/products/datacenter-ips',
            caption: 'Unlimited Bandwidth & Unlimited Connections'
          },
          {
            name: 'Mobile Proxies',
            icon: require('@/assets/menu_icons/mobile_proxy.svg'),
            url: 'https://rayobyte.com/products/mobile-proxies',
            caption: 'Get the best rotating mobile proxies for web scraping!'
          },
          {
            name: 'ISP Proxies',
            icon: require('@/assets/menu_icons/isp_proxy.svg'),
            url: 'https://rayobyte.com/products/isp-proxies',
            caption: 'Get 100% ethically sourced proxies'
          },
          {
            name: 'Scraping Robot',
            icon: require('@/assets/menu_icons/scraping_proxy.svg'),
            url: 'https://rayobyte.com/scraping-robot/',
            caption: 'Scrape websites into JSON with ease!'
          },
        ]
      },
      {
        id: 2,
        name:'Pricing',
        subMenu: true,
        titleCaption: 'All Pricing',
        caption: 'Use our pricing calculator to identify the right mix of products for your use case.',
        url: 'https://rayobyte.com/pricing/',
        children: [
          {
            name: 'Residential Pricing',
            icon: require('@/assets/menu_icons/resi_proxy.svg'),
            url: 'https://rayobyte.com/products/residential-proxies#pricing',
            caption: 'Ethical residential proxies for all of your data needs'
          },
          {
            name: 'ISP Pricing',
            icon: require('@/assets/menu_icons/rotating_isp_proxy.svg'),
            url: 'https://rayobyte.com/products/isp-proxies#pricing',
            caption: 'Get 100% ethically sourced proxies'
          },
          {
            name: 'Data Center Pricing',
            icon: require('@/assets/menu_icons/data_center_proxy.svg'),
            url: 'https://rayobyte.com/products/datacenter-ips#pricing',
            caption: 'Unlimited Bandwidth & Unlimited Connections'
          },
          {
            name: 'Mobile Pricing',
            icon: require('@/assets/menu_icons/mobile_proxy.svg'),
            url: 'https://rayobyte.com/products/mobile-proxies#pricing',
            caption: 'Get the best rotating mobile proxies for web scraping!\n'
          },
          {
            name: 'Scraping Pricing',
            icon: require('@/assets/menu_icons/scraping_proxy.svg'),
            url: 'https://rayobyte.com/scraping-robot/',
            caption: 'Scrape websites into JSON with ease!'
          },
          {
            name: 'Get Custom Pricing',
            icon: require('@/assets/menu_icons/custom_pricing.svg'),
            url: 'https://rayobyte.com/contact-us/',
            caption: 'Start Your 1GB free trial.'
          },
        ]
      },
      {
        id: 3,
        name:'Resell',
        titleCaption: '',
        caption: '',
        url: 'https://rayobyte.com/resell-proxies/',
        subMenu: false
      },
      {
        id: 4,
        name:'Resources',
        subMenu: true,
        titleCaption: '',
        caption: '',
        url: '',
        children: [
          {
            name: 'Enterprise',
            icon: require('@/assets/menu_icons/enterprise.svg'),
            url: 'https://rayobyte.com/enterprise',
            caption: 'Ethical residential proxies for all of your data needs.'
          },
          {
            name: 'Rebranding',
            icon: require('@/assets/menu_icons/rebranding.svg'),
            url: 'https://rayobyte.com/our-story',
            caption: 'New Name, Same Standards'
          },
          {
            name: 'Blog',
            icon: require('@/assets/menu_icons/blog.svg'),
            url: 'https://rayobyte.com/blog/',
            caption: 'Unlimited Bandwidth & Unlimited Connections'
          },
          {
            name: 'Use Cases',
            icon: require('@/assets/menu_icons/use_cases.svg'),
            url: 'https://rayobyte.com/cases/',
            caption: 'Get the best rotating mobile proxies for web scraping!\n'
          },
          {
            name: 'Knowledge Base',
            icon: require('@/assets/menu_icons/knowledge_base.svg'),
            url: 'https://portal.rayobyte.com/en/support/home',
            caption: 'Get 100% ethically sourced proxies'
          },
          {
            name: 'Affiliates',
            icon: require('@/assets/menu_icons/affiliates.svg'),
            url: 'https://rayobyte.com/affiliates/',
            caption: 'Scrape websites into JSON with ease!'
          },
          {
            name: 'API Documentation',
            icon: require('@/assets/menu_icons/api.svg'),
            url: 'https://rayobyte.com/proxy/dashboard/',
            caption: 'Get 100% ethically sourced proxies'
          },
          {
            name: 'About Us',
            icon: require('@/assets/menu_icons/about_us.svg'),
            url: 'https://rayobyte.com/about/',
            caption: 'Ethical'
          },
        ]
      },
    ],

  })
}
</script>

<style scoped>

.mobile-nav-bar {
  display: flex;
  justify-content: end;
  background-color: transparent !important;
  box-shadow: none !important;
}
.mobile-nav-bar .v-app-bar__nav-icon i {
  color: #ffffff;
}
/* The navigation menu */
.navbar {
  /*overflow: hidden;*/
  width: 100%;
  position: relative;
}
.navbar-item {
  padding: 8px 10px;
  display: inline-flex;
  position: relative;
  align-items: center;
}
.sub-nav:hover::after {
  content: "";
  position: absolute;
  top: 40px;
  left: 32%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
/* Navigation links */
.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The subnavigation menu */
.sub-nav {
  display: table-footer-group;
}

/* Subnav button */
.sub-nav .sub-nav-btn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.sub-nav .sub-nav-btn i {
  color: #cecece;
}

/* Add a red background color to navigation links on hover */
.navbar-item:hover a span, .sub-nav:hover .sub-nav-btn span {
  border-bottom: 2px solid #cecece;
  padding-bottom: 3px;
}

/* Style the sub-nav content - positioned absolute */
.sub-nav-content {
  display: none;
  position: absolute;
  width: 620px;
  padding: 20px;
  left: 1px;
  top: 60px;
  background: #FFFFFF;
  box-shadow: 0px 3px 39px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 1;
}
.navbar-item:last-child .sub-nav-content {
  left: -410px;
}

/* Style the sub-nav links */
.sub-nav-content a {
  float: left;
  color: white;
  text-decoration: none;
}

/* Add a grey background color on hover */
.sub-nav-content a:hover {
  background-color: #eee;
  color: black;
}

/* When you move the mouse over the sub-nav container, open the sub-nav content */
.sub-nav:hover .sub-nav-content {
  display: block;
}

.sub-menu-item {
  display: inline-flex;
  width: 50%;
}
.item-content {
  margin: 5px 0;
  width: 92%;
  position: relative;
}
.item-content:hover {
  background: none !important;
}
.item-content:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #07B6BF;
  opacity: 0.1;
}
.menu-icon-wrapper {
  display: inline-block;
  width: 15%;
  height: 100%;
}
.item-caption-wrapper {
  display: inline-block;
  width: 84%;
  text-align: left;
  padding-left: 12px;
}
.menu-icon-wrapper img {
  width: 30px;
}
.item-caption-wrapper .sub-menu-title {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 5px;
}
.item-caption-wrapper .sub-menu-caption {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #505050;
}

/deep/ .mobile-nav-content .v-treeview-node__label a {
  color: #07B6BF;
}
/deep/ .mobile-nav-content .v-treeview-node__label {
  text-align: left;
}

.see-all-wrapper {
  height: 89px;
  background: #F6F7FB;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: space-between;
  margin: 0 -20px -20px;
  padding: 15px 25px;
}
.see-all-wrapper .see-all-item {
  text-align: left;
}
.see-all-wrapper .title {
  font-weight: 700;
  font-size: 13px !important;
  line-height: 20px;
  color: #000000;
  width: 100%;
  margin: 4px 0;
}
.see-all-wrapper .caption {
  font-weight: 500;
  font-size: 11px !important;
  line-height: 18px;
  color: #000000;
}
.see-all-wrapper .learn-more-btn {
  padding: 8px 16px;
  width: 103px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #07B6BF;
}

@media screen and (max-width: 960px) {
  .navbar {
    display: none;
  }
}

</style>
