<template>
  <div>
    <div class="block-title">
      <p>Enter your contact information</p>
    </div>
    <div class="button-block">
      <button>I don't have an account</button>
      <a href="#">I have an account</a>
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
            v-model="user_info.email.value"
            label="Email"
            single-line
            required
            class="custom-input-form"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
            label="Phone number (optional)"
            single-line
            shaped
            class="custom-input-form"
        >

        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
            v-model="user_info.password.value"
            label="Password"
            type="password"
            single-line
            required
            class="custom-input-form"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
            v-model="user_info.confirm_password.value"
            label="Confirm Password"
            type="password"
            single-line
            required
            class="custom-input-form"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-icon large>error_outline</v-icon>

    <h2>111</h2>
  </div>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      user_info: {
        email: {value: '', errors: [], rules: ['required', 'email']},
        password: {value: '', errors: [], rules: ['required', {rule: "min", option: 6}, 'pass']},
        confirm_password: {value: '', errors: [], rules: ['required']},
      }
    }

  }
}
</script>

<style lang="sass">
@import '~vuetify/src/components/VTextField/_variables.scss'

$text-field-outlined-fieldset-border-width: 0 !default
$text-field-solo-control-min-height: 48px !default

//@media #{map-get($display-breakpoints, 'md-and-down')}

//  .custom-class
//    display: none
</style>

<style scoped>
/* Stepper end */
.custom-input-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 12px !important;
  background: #FFFFFF;
  box-shadow: none;
  border: 1px solid #E3E9ED;
  border-radius: 4px;
  height: 40px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.custom-input-form > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}
.custom-input-form.v-text-field > .v-input__control > .v-input__slot:before,
.custom-input-form.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: hidden !important;
}
.v-textarea >>> .v-input__slot {
  display: none !important;
}
.custom-input-form.theme--light.v-textarea >>> .v-input__slot:before {
  border: none !important;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: hidden !important;
}

</style>

