<template>
    <v-app>
        <v-row>
            <v-card
                    class="mx-auto mt-10"
                    max-width="350"
                    max-height="200"
                    outlined
            >
                <v-list-item three-line>
                    <v-list-item-content>
                        <div class="overline mb-4">

                        </div>
                        <v-list-item-title class="headline mb-1">
                            PAGE NOT FOUND
                        </v-list-item-title>
                        <v-list-item-subtitle>Please go to home page end try find your page </v-list-item-subtitle>
                        <v-btn class="mt-10" to="/">Home</v-btn>
                    </v-list-item-content>

                </v-list-item>

            </v-card>
        </v-row>
    </v-app>


</template>

<script>
    export default {
        name: "NotFoundComponent"
    }
</script>

<style scoped>

</style>
