<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {

  },

  data: () => ({
    //
  }),
};
</script>

<style>
/*@import url("https://fonts.googleapis.com/css?family=Inter:300,400,700");*/

.v-application {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #282828;
  overflow: hidden;
}
</style>
