<template>
  <v-row class="additional-links-wrapper">
    <v-col lg="6" md="12" sm="12" align="right" class="additional-links">
      <a v-for="link in additionalLinks"
         :href="link.url"
         target="_blank"
         class="additional-link-item"
      >
        <v-icon v-if="link.icon">{{ link.icon }}</v-icon>
        <span v-if="link.icon" class="icon-link">{{ link.title }}</span>
        <span v-else>{{ link.title }}</span>
      </a>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "AdditionalLinks",
  data: () => {
    return {
      additionalLinks: [
        {
          'title':'Contact Us',
          'url': 'https://rayobyte.com/contact-us/',
          'icon': false
        },
        {
          'title':'Enterprise',
          'url': 'https://rayobyte.com/enterprise/',
          'icon': false
        },
        {
          'title':'Log in',
          'url': 'https://rayobyte.com/proxy/dashboard/login-type',
          'icon': 'login'
        },
      ],
    }
  }
}
</script>

<style scoped>
.additional-links-wrapper {
  margin: 0;
  padding: 0 50px;
  display: flex;
  justify-content: end;
}
.additional-links {
  padding: 4px;
}
.additional-link-item {
  color: #ffffff;
  text-decoration: none;
  border-right: 2px solid #5F5F5F;
  padding: 0 16px;
}
.additional-link-item:last-child {
  border: none;
  padding-right: 0;
}
.additional-link-item:hover {
  color: #07B6BF;
}
.additional-link-item i {
  margin-right: 5px;
  font-size: 18px;
  line-height: 18px;
  color: #07B6BF;
  height: 20px;
  display: inline-block;
}
.additional-link-item .icon-link {
  color: #07B6BF;
}
</style>
