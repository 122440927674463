<template>
    <v-app style="background: #F6F7FB">
        <v-main app>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

    }
</script>

<style lang="scss">
    $flag-icon-css-path: '~flag-icon-css/flags';
    @import '~flag-icon-css/sass/flag-icon';


</style>
